<template>
  <!-- 团员成员 -->
  <div class="members_conetnt">
    <div class="top">
      <!-- 团队成绩情况 -->
      <span class="top-left">按累计采纳数</span>
      <span class="top-right">
        <input
          type="text"
          id="search"
          class="search-icon"
          @keyup.enter="name"
          placeholder="输入用户名称关键字"
        />
        <span class="search-bar" @click="search()">搜索</span>
      </span>
    </div>
    <!-- 团队信息 -->
    <div class="center">
      <div v-for="(item, index) in now" :key="index">
        <div class="center-content" v-if="index < 3">
          <span class="center_num1" v-if="index == 0">{{ index + 1 }}</span>
          <span class="center_num2" v-if="index == 1">{{ index + 1 }}</span>
          <span class="center_num3" v-if="index == 2">{{ index + 1 }}</span>
          <span class="center_num4" v-if="index == 3">4</span>
          <span class="imgs">
            <img class="img" :src="item.img" @click="see(item.UserID)" />
          </span>
          <span class="name">{{ item.name }}</span>
          <!-- 团员成绩情况 -->
          <div class="center_num">
            <span class="center_Line">{{ item.adopt }}</span>
            <span>{{ item.answer }}</span>
            <span class="center_Line">采纳数</span>
            <span>回答数</span>
          </div>
        </div>
        <!-- 3位以后的团员 -->
        <div class="bottom" v-if="index > 2">
          <span class="bottom-index">{{ item.index }}</span>
          <span class="bottom-img">
            <img class="imge" :src="item.img" />
          </span>
          <span class="bottom-name" @click="see(item.UserID)">{{
            item.name
          }}</span>
          <span class="bottom-adopt">
            <span>采纳数</span>
            {{ item.adopt }}
          </span>
          <span class="bottom-answer">
            <span>回答数</span>
            {{ item.answer }}
          </span>
          <span class="bottom-time">入团时间: {{ item.time }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate, showTimeFormat } from "@/js/common";
import { QUERY, UPDATE, DELETE, QUERYED } from "@/services/dao.js";
export default {
  data() {
    return {
      //  now:[],
      number: 0,
      input: "",
    };
  },
  props: {
    now: {
      type: Array,
      required: true,
    },
  },
  created() {
    //   this.now=new Date().getTime();
    //初始化加载
    // this.init()
  },
  methods: {
    //查看团队成员的回答列表
    async see(UserID) {
      this.$router.push({
        path: "/answer-list",
        query: {
          id: UserID,
        },
      });
    },
    async name() {
      this.search();
    },
    //     ///加载团员信息
    async init() {
      this.number = this.$route.query.id;
      //alert(this.numbers)
      let info = await QUERYED(
        "post",
        "",
        "   WDMemberTeam(where: {id: {_eq: " +
          this.number +
          "}}){ userid id  img    name   wdAcceptNum  wdAnswerNum    joinTime }"
      );
      console.log(info);

      this.now.splice(0, this.now.length);
      for (let i = 0; i < info.data.WDMemberTeam.length; i++) {
        //  // 回答个数
        let data = await QUERYED(
          "post",
          "",
          "   WDUserQuestionAnswerRef_aggregate(where: {id: {_eq: " +
            info.data.WDMemberTeam[i].userid +
            "}}) { aggregate {  count(columns: wdanswerref)    }  }"
        );
        this.now.push({
          id: info.data.WDMemberTeam[i].id,
          name: info.data.WDMemberTeam[i].name,
          adopt: info.data.WDMemberTeam[i].wdAcceptNum,
          answer: data.data.WDUserQuestionAnswerRef_aggregate.aggregate.count,
          time: formatDate(Date.parse(info.data.WDMemberTeam[i].joinTime)),
          img: this.api.LoginURL.concat(info.data.WDMemberTeam[i].img),
          UserID: info.data.WDMemberTeam[i].userid,
        });
      }
    },
    ///按照姓名搜索
    async search() {
      this.input = document.getElementById("search").value;
      //alert(this.input)
      if (this.input != "") {
        this.searchname();
      } else {
        this.init();
      }
    },
    async searchname() {
      this.number = this.$route.query.id;
      this.input = document.getElementById("search").value;
      let info = await QUERYED(
        "post",
        "",
        "  WDMemberTeam(where: {id: {_eq: " +
          this.number +
          '}, name: {_like: "%' +
          this.input +
          '%"}}){ userid id  img    name   wdAcceptNum  wdAnswerNum    joinTime }'
      );
      console.log(info);
      //alert(info)
      this.now.splice(0, this.now.length);
      for (let i = 0; i < info.data.WDMemberTeam.length; i++) {
        this.now.push({
          id: info.data.WDMemberTeam[i].id,
          name: info.data.WDMemberTeam[i].name,
          adopt: info.data.WDMemberTeam[i].wdAcceptNum,
          answer: info.data.WDMemberTeam[i].wdAnswerNum,
          time: formatDate(Date.parse(info.data.WDMemberTeam[i].joinTime)),
          img: this.api.LoginURL.concat(info.data.WDMemberTeam[i].img),
          UserID: info.data.WDMemberTeam[i].userid,
        });
      }
      //alert(this.now)
    },
  },
};
</script>
<style scoped>
.members_conetnt {
  /* border: 1px solid red; */
  width: 100%;
  display: inline-block;
}
.top {
  width: 100%;
  height: 50px;
  line-height: 50px;
}
.top-left {
  width: 75%;
  display: inline-block;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  margin-left: 10px;
}
.search-icon {
  width: 160px;
  height: 25px;
  border-radius: 3px;
  border: 1px solid rgba(220, 220, 220, 1);
}
.search-bar {
  width: 60px;
  height: 28px;
  line-height: 28px;
  border-radius: 3px;
  text-align: center;
  background: rgba(0, 132, 255, 1);
  display: inline-block;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(255, 253, 253, 1);
  position: relative;
  top: 1px;
  cursor: pointer;
}
.center {
  background-color: white;
  width: 100%;
  display: inline-block;
  border: 1px solid rgba(220, 220, 220, 1);
  padding-top: 40px;
  padding-bottom: 40px;
}
.center-content {
  padding-top: 10px;
  /* padding-left: 10px; */
  width: 20%;
  /* display:inline-block; */
  float: left;
  margin: auto;
  border: 1px solid rgba(220, 220, 220, 1);
  margin-right: 85px;
  /* text-align: center; */
  margin-left: 56px;
}
.img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
}
.imge {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 0;
  position: relative;
  top: 20px;
}
.imgs,
.name {
  text-align: center;
  width: 100%;
  /* text-align: center; */
  display: inline-block;
}
.name {
  height: 50px;
  line-height: 50px;
}
.center_num {
  width: 100%;
  /* height: 80px; */
  background: rgba(238, 238, 238, 1);
  display: inline-block;
  text-align: center;
  padding-top: 20px;
  color: rgba(139, 138, 138, 1);
  padding-bottom: 20px;
  /* line-height: 100px; */
}
.center_num > span {
  width: 49%;
  height: 30px;
  display: inline-block;
  text-align: center;
  /* border: 1px solid green; */
  /* margin-top: 20px; */
}
.center_Line {
  border-right: 2px solid rgba(220, 220, 220, 1);
}
.center_num1 {
  width: 95%;
  margin-left: 10px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 800;
  color: rgba(229, 3, 3, 1);
  display: inline-block;
  /* border: 1px solid forestgreen; */
}
.center_num2 {
  width: 95%;
  margin-left: 10px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 800;
  color: rgba(229, 109, 3, 1);
  display: inline-block;
}
.center_num3 {
  width: 95%;
  margin-left: 10px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 800;
  color: rgba(9, 162, 95, 1);
  display: inline-block;
}
.bottom {
  width: 95%;
  display: inline-block;
  /* border: 1px solid red; */
  margin-left: 30px;
}
.bottom > span {
  height: 30px;
  line-height: 30px;
  display: inline-block;
}
.bottom-index {
  width: 30px;
  /* line-height: 30px; */
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(5, 5, 5, 1);
}
.bottom-img {
  width: 70px;
}
.bottom-name {
  width: 20%;
  display: inline-block;
  /* border: 1px solid green; */
  /* margin-right: 60px; */
  margin-left: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(5, 59, 139, 1);
  cursor: pointer;
  /* text-decoration: aqua; */
}
.bottom-name:hover {
  text-decoration: underline;
}
.bottom-adopt {
  width: 20%;
  display: inline-block;
  /* border: 1px solid green; */
  margin-right: 50px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(5, 5, 5, 1);
}
.bottom-answer {
  width: 15%;
  display: inline-block;
  /* border: 1px solid green;    */
  margin-right: 50px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(5, 5, 5, 1);
}
.bottom-time {
  width: 20%;
  display: inline-block;
  /* border: 1px solid green;     */
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(5, 5, 5, 1);
}
input {
  outline: none;
}
</style>
